export const QUERY_PARAM = {
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  MODE: 'mode',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  FILTERS: 'filters',
  ADVANCED_SEARCH: 'advancedSearch',
  SORTING: 'sorting',
  ASCENDING: 'ascending',
  SEARCH_TERM: 'searchTerm',
  PAGE: 'page',
  ID: 'id',
  TAB: 'tab',
} as const;

type Keys = keyof typeof QUERY_PARAM;
export type KnownQueryParams = (typeof QUERY_PARAM)[Keys];
